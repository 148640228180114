<template>
  <div class="groupLeaderTreeBox">
    <div v-for="(item, index) in data" :key="index">
      <div class="departmentName itemStyle">
        <el-checkbox
          v-model="item.isCheck"
          @change="(val) => checkChange(val, item, 'group')"
        ></el-checkbox>
        <div @click="changeCheck(item, 'group')" class="itemStyle">
          <img src="@/assets/image/departmentSvg.png" />部门名称：{{ item.name }}
        </div>
      </div>
      <div class="personName" v-for="(item1, index1) in item.user_list" :key="index1">
        <div class="itemStyle">
          <el-checkbox
            v-model="item1.isCheck"
            @change="(val) => checkChange(val, item1, 'person', item)"
          ></el-checkbox>
          <div class="itemStyle" @click="changeCheck(item1, 'person', item)">
            <img src="@/assets/image/personSvg.png" />
            {{ item1.name }}<span v-if="item1.alias">({{ item1.alias }})</span>
          </div>
        </div>
      </div>
      <GroupLeaderTree class="sonData" v-if="item._child" :data="item._child"></GroupLeaderTree>
    </div>
  </div>
</template>

<script>
import { bus } from '@/util/bus'
export default {
  name: 'GroupLeaderTree',
  props: {
    data: {
      type: Array,
      default: () => []
    },
    first: {
      type: Object,
      default: () => false
    }
  },
  mounted() {
    if (this.first) {
      bus.$on('getListResult', (type, list) => {
        this.$emit('getResult', type, list)
      })
    }
  },
  beforeDestroy() {
    bus.$off(['getListResult'])
  },
  methods: {
    // 点击文本进行全选反选
    changeCheck(item, type, fatherItem) {
      item.isCheck = !item.isCheck
      this.dealFatherStatus(item.isCheck, item, type, fatherItem)
    },
    // 当多选框进行全选反选时
    checkChange(val, item, type, fatherItem) {
      this.dealFatherStatus(val, item, type, fatherItem)
    },
    // 改变状态后处理父节点选中状态
    dealFatherStatus(val, item, type, fatherItem) {
      if (type === 'group') {
        if (val) {
          item.user_list.map((item1) => (item1.isCheck = true))
          if (this.first) {
            this.$emit('getResult', 'add', item.user_list)
          } else {
            bus.$emit('getListResult', 'add', item.user_list)
          }
        } else {
          item.user_list.map((item1) => (item1.isCheck = false))
          if (this.first) {
            this.$emit('getResult', 'remove', item.user_list)
          } else {
            bus.$emit('getListResult', 'remove', item.user_list)
          }
        }
      } else {
        if (!val) {
          fatherItem.isCheck = false
          if (this.first) {
            this.$emit('getResult', 'remove', [item])
          } else {
            bus.$emit('getListResult', 'remove', [item])
          }
        } else {
          if (this.first) {
            this.$emit('getResult', 'add', [item])
          } else {
            bus.$emit('getListResult', 'add', [item])
          }
        }
      }
    },
    getResult(type, list) {
      this.$emit('getResult', type, list)
    }
  }
}
</script>

<style lang="less" scoped>
.departmentName,
.personName {
  margin: 5px 0;
}
.el-checkbox {
  margin-right: 5px;
}
.personName {
  margin-left: 10px;
}
.sonData {
  margin-left: 10px;
}
.itemStyle {
  display: flex;
  align-items: center;
  img {
    margin-right: 3px;
  }
}
</style>
